import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from 'src/app/models/matDialogData.model';

@Component({
  selector: 'dashboard-alerts-app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  confirmationHeader!: string;
  confirmationQuestion!: string;
  patientId!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmDialogData
  ) {

  }

  ngOnInit(): void {
    this.confirmationHeader = this.dialogData?.confirmationHeader;
    this.confirmationQuestion = this.dialogData?.confirmationQuestion;
  }

}
