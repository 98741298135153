import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil, tap } from 'rxjs';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom-breakpoint-state-interface';
import { INavbarRoute } from 'src/app/shared/interfaces/navbar-route';

@Component({
  selector: 'dashboard-alerts-app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  navLinks: Array<INavbarRoute> = [];
  navLabels = ['Summary', 'Predictive Analytics'];
  private unsubscribe$ = new Subject<void>();

  @HostBinding('class.tablet') isTablet: boolean = false;
  @HostBinding('class.sidebar-visible') isSidebarVisible = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  @Output() onTabChange = new EventEmitter<INavbarRoute>();

  constructor(
    private breakpointService: BreakpointService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    /*
    * generate links based on patient id for proper highlighting 
    * and navigation of material tab navigation.
    */
    this.navLinks = this.navLabels.map((label: string) => {
      const location = label.toLowerCase().split(' ').join('-');
      return { location, label, title: label === 'Summary' ? 'Dashboard' : label }
    })

    /**
     * Get the current route and emit, so that when route changes, correct
     * data is propagated to parent component
     */
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      tap((event: any) => {
        const url = String(event.url).split('/')[1];
        const currentTab = this.navLinks.find((link: INavbarRoute) => link.location === url);
        if (currentTab) {
          this.onTabChange.emit(currentTab)
        }
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe()

    /**
     * Listen for breakpoint changes
     */
    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: CustomBreakpointState) => {
        this.isTablet = value.Tablet;
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });
  }

  public navLinkTrackBy(index: number, item: any) {
    return item.location
  }

}
