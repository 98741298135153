import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-alerts-app-summary-status-cell',
  templateUrl: './summary-status-cell.component.html',
  styleUrls: ['./summary-status-cell.component.scss']
})
export class SummaryStatusCellComponent implements OnInit {
  /** Text to show as status inside the button */
  @Input() statusText!: string;

  /** Extra data any if required  */
  @Input() data?: any = null;
  constructor() { }

  ngOnInit(): void {
  }

}
