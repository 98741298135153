import '@zonejs-patch';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MsalInterceptor,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  InteractionType,
  IPublicClientApplication,
} from '@azure/msal-browser';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { SummaryComponent } from './components/summary/summary.component';
import { PredictiveAnalyticsComponent } from './components/predictive-analytics/predictive-analytics.component';
import { NotificationComponent } from './components/notification/notification.component';
import { SharedModule } from './shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SummaryAlertsCardComponent } from './components/summary-alerts-card/summary-alerts-card.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { getMsalInstance } from '@bayshoreHealthCare/store';
import { SentryErrorHandlerService } from './services/sentry-error-handler.service';

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);
  protectedResourceMap.set('https://devvantage-api.bayshoredigital.ca/api/fhir-service', []);

  https: return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {},
    loginFailedRoute: '/login-failed',
  };
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return getMsalInstance();
}

@NgModule({
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    SummaryComponent,
    PredictiveAnalyticsComponent,
    NotificationComponent,
    HeaderComponent,
    NavbarComponent,
    SummaryAlertsCardComponent,
    ConfirmDialogComponent
  ],
  imports: [
    SharedModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalBroadcastService,
    MsalGuard,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandlerService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
