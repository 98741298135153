<div class="temperature-cell-container" *ngIf="value">
    <div class="value-container">
        <span>{{ value }} {{unit}}</span>
    </div>
    <div class="icon-container">
        <img src="{{'images/temperature.png'|assetUrl}}" class="temperature-icon">
    </div>
</div>

<div class="temperature-cell-container" *ngIf="!value && observationValue">
    <div class="value-container">
        <span>{{ observationValue }} C</span>
    </div>
    <div class="icon-container">
        <img src="{{'images/temperature.png'|assetUrl}}" class="temperature-icon">
    </div>
</div>

<div class="temperature-cell-container" *ngIf="!value && !observationValue">
    <div class="value-container">
        <span>-- C</span>
    </div>
    <div class="icon-container">
        <img src="{{'images/temperature.png'|assetUrl}}" class="temperature-icon">
    </div>
</div>