import { Bundle, BundleEntry, FhirResource, Resource } from "fhir/r4";


/**
 * To be used when a Bundle contains n types of resources
 * @param bundle Bundle including types T and U
 * @param resource1Type resourceType of FhirResource
 * @param @optional resource2Type resourceType of FhirResource
 * @param @optional resource3Type resourceType of FhirResource
 * @returns 3 arrays each containing one resource type items
 */
export const MapBundleToResourceArrays = <T extends Resource, U extends Resource, V extends Resource = any>(
  bundle: Bundle<T | U | V>,
  resource1Type: string,
  resource2Type?: string,
  resource3Type?: string
): { resource1: T[], resource2: U[], resource3: V[] } => {
  const resource1: T[] = [];
  const resource2: U[] = [];
  const resource3: V[] = [];

  if (!bundle?.entry?.length) {
    return {
      resource1,
      resource2,
      resource3
    };
  }
  bundle.entry.forEach((bundleEntry: BundleEntry<T | U | V>) => {
    if (bundleEntry?.resource?.resourceType === resource1Type) {
      resource1.push(bundleEntry.resource as T);
    }
    if (bundleEntry?.resource?.resourceType === resource2Type) {
      resource2.push(bundleEntry.resource as U);
    }
    if (!!resource3Type && bundleEntry?.resource?.resourceType === resource3Type) {
      resource3.push(bundleEntry.resource as V);
    }
  });
  return {
    resource1,
    resource2,
    resource3
  };
}

/**
 * R2-877: Move patientIds appended in the URL to body cause of length restriction for FHIR endpoints
 * @param url 
 * @returns 
 */
export const BundleGetRequest = (url: string): Bundle => {
  return {
    resourceType: 'Bundle',
    type: 'transaction',
    entry: [
      {
        request:
        {
          method: 'GET',
          url
        }
      }
    ]
  };
}

/**
 * FHIR resource Bundle in BundleGETRequest's response is in bundle.entry[0].resource
 * @param bundle 
 * @returns 
 */
export const MapBundledRequestResponseToBundle = <T extends FhirResource>(bundle: Bundle): Bundle<T> => {
  if (!bundle?.entry?.length) {
    return {
      resourceType: 'Bundle',
      type: 'collection',
      entry: [],
    }
  }
  return bundle.entry[0].resource as Bundle<T>;
}

/**
 * Extracting FHIR Bundle to specified resource type array
 * @param bundle Bundle containing resource type T
 * @returns Array containing resource objects of type T
 */
export const MapBundleToResourceArray = <T>(bundle: Bundle<T>): T[] => {
  return bundle?.entry?.length
    ? bundle.entry
      .filter((bundleEntry: any) => !bundleEntry.resource?.issue)
      .map((bundleEntry: BundleEntry<T>) => bundleEntry.resource!)
    : [];
}