<nav mat-tab-nav-bar class="mat-elevation-z8 dashboard-white-navbar dashboard-my-tab-nav" [tabPanel]="tabPanel">
    <ng-container *ngFor="let link of navLinks;trackBy: navLinkTrackBy">
        <a mat-tab-link class="dashboard-custom-tab" *ngIf="link.label!=='Predictive Analytics'"
            [routerLink]="link.location" routerLinkActive="active-tab" #rla="routerLinkActive" [active]="rla.isActive">
            <span>{{ link.label | uppercase }}</span>
        </a>
    </ng-container>
</nav>
<mat-tab-nav-panel class="dashboard-custom-tabpanel" #tabPanel>
    <router-outlet></router-outlet>
</mat-tab-nav-panel>