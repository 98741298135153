<div class="dashboard-summary-card" cdkDrag [cdkDragData]="cdkDragData"
cdkDragBoundary=".dashboard-summary-alerts-container" cdkDragPreviewClass="dashboard-summary-card-drag" [style]="style" (click)="handleClick()">
    <span class="dashboard-summary-card-title">{{title}}</span>
    <div class="dashboard-summary-card-row">
        <div class="dashboard-summary-card-colum">
            <span class="dashboard-summary-card-count"> {{count}}</span>
            <span class="dashboard-summary-card-subtitle">{{subtitle}}</span>
        </div>
        <div class="dashboard-summary-card-colum">
            <img [src]="imageUrl" [alt]="title" class="icon">
        </div>
    </div>
</div>