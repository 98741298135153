import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { filter, Observable, switchMap } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { parseIdTokenClaims } from 'src/app/utils/shared.util';
import { IIdTokenClaimsParsed, IIdTokenClaims } from '../interfaces/id-token-claims';
import { onOrganizationChange$ } from '@bayshoreHealthCare/store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  activeAccount: AuthenticationResult | null = null;
  token!: string;
  idTokenClaims!: IIdTokenClaimsParsed;
  rpmWebHeaders = {
    appType: 'Provider',
    requestor: 'Vantage'
  };

  /** Active organization selected for sidebar / Saved active organization  */
  activeOrganization: Array<string> = [];

  constructor(
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
    ).subscribe({
      next: (value: EventMessage) => {
        const { idToken, idTokenClaims } = value.payload as AuthenticationResult;
        this.token = idToken;
        this.idTokenClaims = parseIdTokenClaims(idTokenClaims as IIdTokenClaims);
      }
    });

    /**Listen for organization switch from sidebar */
    onOrganizationChange$
      .subscribe({
        next: (organization: Array<string>) => {
          this.activeOrganization = organization;
        }
      })
  }

  /** set activeProfile Headers based on active organization
    * selected from sidebar / read from localstorage
    */
  generateHeaders = () => {
    if (this.activeOrganization.length) {
      return {
        ...this.rpmWebHeaders,
        Authorization: `Bearer ${this.token}`,
        activeProfile: `${this.activeOrganization[0]}`
      }
    }
    return {
      ...this.rpmWebHeaders,
      Authorization: `Bearer ${this.token}`,
      activeProfile: `${this.idTokenClaims.appRole[0][0]}`
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.token && this.idTokenClaims) {
      const authRequest = request.clone({
        setHeaders: this.generateHeaders()
      });
      return next.handle(authRequest);
    }
    return this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      switchMap((result: EventMessage) => {
        const { idToken, idTokenClaims } = result.payload as AuthenticationResult;
        this.token = idToken;
        this.idTokenClaims = parseIdTokenClaims(idTokenClaims as IIdTokenClaims);
        const authRequest = request.clone({
          setHeaders: this.generateHeaders()
        });
        return next.handle(authRequest);
      }),
    );
  }
}