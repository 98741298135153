import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { Bundle, CarePlan, PlanDefinition } from 'fhir/r4';

@Injectable({
  providedIn: 'root'
})
export class CareplanService extends BaseApiService {
  /**
   * emits selected program name change in dashboard
   * default value 'all' won't apply 'careplan-name' query string to API calls
   */
  programSelectionChanged$ = new BehaviorSubject<string>('all');

  /**
   * trigger fetch active users API call
   * triggered when alerts table data is refreshed
   */
  fetchActiveUsers$ = new Subject<void>();

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getActiveProgramByPatientId(patientFhirId: string): Observable<CarePlan | null> {
    return this.http.get(`${this.FHIR_BASE}/CarePlan?patient=${patientFhirId}&status=active`)
      .pipe(
        map((response: any) => {
          if (response?.entry?.length) {
            return response?.entry?.[0].resource as CarePlan;
          }
          return null;
        })
      );
  }

  getAllCarePlans(): Observable<Bundle<PlanDefinition>> {
    return this.http.get<Bundle<PlanDefinition>>(`${this.FHIR_BASE}/PlanDefinition?_count=1000`);
  }
}
