<div class="pulse-oximeter-cell-container" *ngIf="value">
    <div class="value-container">
        <span>{{ value }} {{unit}}</span>
    </div>
    <div class="icon-container">
        <img src="{{'images/spo2.png'|assetUrl}}" class="pulse-oximeter-icon">
    </div>
</div>

<div class="pulse-oximeter-cell-container" *ngIf="!value && observationValue">
    <div class="value-container">
        <span>{{ observationValue}} percent</span>
    </div>
    <div class="icon-container">
        <img src="{{'images/spo2.png'|assetUrl}}" class="pulse-oximeter-icon">
    </div>
</div>

<div class="pulse-oximeter-cell-container" *ngIf="!value && !observationValue">
    <div class="value-container">
        <span>-- percent</span>
    </div>
    <div class="icon-container">
        <img src="{{'images/spo2.png'|assetUrl}}" class="pulse-oximeter-icon">
    </div>
</div>