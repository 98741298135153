import { Injectable } from '@angular/core';
import { api } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {

  protected FHIR_BASE = api.fhirBase;
  
  constructor(
  ) { }
}
