import { Patient } from "fhir/r4";

export const filterPatientNameFromPatient = (patient: Patient): string => {
    return `${patient.name?.[0].given?.join(' ') || ''} ${patient.name?.[0].family || ''}`
}

/**
 * Maps patient fhir id and returns bundle request payload
 * to get patient, condition, careplan and goals in careplan
 * as a single bundle
 * @param patientFhirId Patient's Fhir Id
 * @returns mapped bundle request payload
 */
export const mapPatientProgramDiagnosisGoalsBundle = (patientFhirId: string) => {
    return {
        "resourceType": "Bundle",
        "type": "batch",
        "entry": [
            {
                "request": {
                    "method": "GET",
                    "url": `/Patient?_id=${patientFhirId}&_revinclude=Condition:patient&_sort=-_lastUpdated`
                }
            },
            {
                "request": {
                    "method": "GET",
                    "url": `/CarePlan?patient=${patientFhirId}&status=active&_include=CarePlan:goal`
                }
            }
        ]
    }
}

export const GetPatientIdsAsArray = (patients: Patient[]): string[] => {
  if (!patients?.length) {
    return [];
  }
  return patients
    .map(({ id = '' }: Patient) => id)
    .filter(Boolean);
}