import { assetUrl } from "src/single-spa/asset-url";

export const customBreakpoints = {
    XSmall: '(max-width: 599px)',
    Small: '(min-width: 600px) and (max-width: 959px)',
    Medium: '(min-width: 960px) and (max-width: 1279px)',
    Tablet: '(min-width: 600px) and (max-width: 1279px)',
    Large: '(min-width: 1280px) and (max-width: 1919px)',
    XLarge: '(min-width: 1920px) and (max-width:2303px)',
    Retina: '(min-width: 2304px) and (max-width:3023px)',
    RetinaXDR: '(min-width: 3024px) and (max-width:4095px)',
    Retina4k: '(min-width: 4096px) and (max-width:5119px)',
    Retina5k: '(min-width: 5120px)',
}

export const notificationDuration = 3000;
export const spinnerProperties = {
    diameter: 50,
    strokeWidth: 2.5
};

export const MatTableDefaultPageSizes = [10, 20, 30, 40];

/**
 * Displayed Columns for All Alerts table in summary
 */
export const summaryAlertsTableColumns = [
    {
        column: 'patientName',
        title: 'Patient Name'
    },
    {
        column: 'programName',
        title: 'Program'
    },
    {
        column: 'diagnosis',
        title: 'Diagnosis'
    },
    // {
    //     column: 'patientStatus',
    //     title: 'Patient Status'
    // },
    {
        column: 'heartRate',
        title: 'Heart Rate'
    },
    {
        column: 'spo2',
        title: 'SPO2'
    },
    {
        column: 'bloodPressure',
        title: 'Blood Pressure'
    },
    {
        column: 'temperature',
        title: 'Temperature'
    },
    {
        column: 'bloodGlucose',
        title: 'Blood Glucose'
    },
    {
        column: 'weight',
        title: 'Weight'
    },
]

export const systemFilters = [
    {
        column: 'patientName',
        title: 'Patient Name'
    },
    {
        column: 'programName',
        title: 'Program'
    },
    {
        column: 'diagnosis',
        title: 'Diagnosis'
    },
    {
        column: 'patientStatus',
        title: 'Patient Status'
    },
];

export const metricsFilters = [
    {
        column: 'heartRate',
        title: 'Heart Rate'
    },
    {
        column: 'spo2',
        title: 'SPO2'
    },
    {
        column: 'bloodPressure',
        title: 'Blood Pressure'
    },
    {
        column: 'temperature',
        title: 'Temperature'
    },
    {
        column: 'bloodGlucose',
        title: 'Blood Glucose'
    },
    {
        column: 'weight',
        title: 'Weight'
    }
]

export const OBSERVATION_CODES = {
    HEART_RATE: '8867-4',
    SPO2: '59408-5',
    BLOOD_PRESSURE: '85354-9',
    TEMPERATURE: '8310-5',
    BLOOD_GLUCOSE: '15074-8',
    WEIGHT: '29463-7'
}

export const BLOOD_PRESSURE_CODES = {
    SYSTOLIC: '8480-6',
    DIASTOLIC: '8462-4'
}

export const ALERT_TITLES = {
    TOTAL_ALERTS: 'Total Active Alerts',
    ALL_ALERTS: 'All Alerts',
    ADHERENCE_ALERTS: 'Adherence Alerts',
    HEART_RATE_ALERTS: 'Heart Rate Alerts',
    SPO2_ALERTS: 'SPO2 Alerts',
    BLOOD_PRESSURE_ALERTS: 'Blood Pressure Alerts',
    TEMPERATURE_ALERTS: 'Temperature Alerts',
    BLOOD_GLUCOSE_ALERTS: 'Blood Glucose Alerts',
    WEIGHT_ALERTS: 'Weight Alerts'

}

export const defaultSummaryAlertCardValues = [
    {
        title: ALERT_TITLES.TOTAL_ALERTS,
        subtitle: 'overall',
        count: 0,
        cardBackground: '#4A4A4A',
        imageUrl: assetUrl('images/total-active-alerts.png')
    },
    {
        title: ALERT_TITLES.ADHERENCE_ALERTS,
        subtitle: 'today',
        count: 0,
        cardBackground: '#2797B6',
        imageUrl: assetUrl('images/adherence-alerts.png'),
    },
    {
        title: ALERT_TITLES.HEART_RATE_ALERTS,
        subtitle: 'overall',
        count: 0,
        cardBackground: '#ED1B24',
        imageUrl: assetUrl('images/heart-rate-alerts.png'),
    },
    {
        title: ALERT_TITLES.SPO2_ALERTS,
        subtitle: 'overall',
        count: 0,
        imageUrl: assetUrl('images/spo2-alerts.png'),
    },

    {
        title: ALERT_TITLES.BLOOD_PRESSURE_ALERTS,
        subtitle: 'overall',
        count: 0,
        cardBackground: '#ED1B24',
        imageUrl: assetUrl('images/blood-pressure-alerts.png'),
    },
    {
        title: ALERT_TITLES.TEMPERATURE_ALERTS,
        subtitle: 'overall',
        count: 0,
        cardBackground: '#FF8800',
        imageUrl: assetUrl('images/temperature-alerts.png'),
    },
    {
        title: ALERT_TITLES.BLOOD_GLUCOSE_ALERTS,
        subtitle: 'overall',
        count: 0,
        imageUrl: assetUrl('images/blood-glucose-alerts.png'),
    },
    {
        title: ALERT_TITLES.WEIGHT_ALERTS,
        subtitle: 'overall',
        count: 0,
        imageUrl: assetUrl('images/weight-alerts.png'),
    }
]

export const API_POLLING_DURATION = 2 * 60000 // 2 minutes

export const INACTIVE_DURATION = 10 * 60 * 1000; // 10 minutes;

export const DefaultResourceQueryCount = 1000;

export enum FhirResourceType {
    Patient = 'Patient',
    CarePlan = 'CarePlan',
    Task = 'Task',
    Goal = 'Goal',
    Condition = 'Condition',
    ServiceRequest = 'ServiceRequest',
}

// confirm dialog properties
export const DialogResponsiveWidth = {
    medium: '750px',
    tablet: '750px',
    large: '750px',
    xlarge: '750px',
    retina: '900px',
    retinaXdr: '1181.25px',
    retina4k: '1600px',
}
export const DefaultDialogProperties = {
    width: DialogResponsiveWidth.large, //default to '750px',
    disableClose: true,
    panelClass: 'custom-dialog-container',
};

export interface ApiConfig { 
  fhirBase: string;
};