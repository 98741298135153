import { Condition } from "fhir/r4";

export const filterDiagnosisName = (diagnosis: any): string => {
  if (!diagnosis?.code?.coding?.length) {
    return '';
  }
  return diagnosis?.code?.coding
    .map((coding: any) => coding?.display)
    .filter(Boolean)
    .join(',');
};