<div class="dashboard-example-container mat-elevation-z8">
  
  <section class="custom-table-container">
    <mat-table #table [dataSource]="tableDataSource" [trackBy]="trackBy" matSort matSortDisableClear multiTemplateDataRows
      [ngClass]="{'custom-table-min-height':!showSpinner && tableDataSource.data.length}">
    
      <ng-container [matColumnDef]="item.column" *ngFor="let item of displayedColumns; let i = index;">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ item.title }}
        </mat-header-cell>
    
        <mat-cell *matCellDef="let element" [matTooltip]="element.description" (click)="redirectToPatientSummary(element)">
          <ng-container [ngSwitch]="type">
            <!-- cell component for the alerts table -->
            <ng-container *ngSwitchCase="'alertsTable'">
              <!-- If its alerts table then how appropriate cells -->
              <ng-container [ngSwitch]="item.column">
                <ng-container *ngSwitchCase="'patientName'">
                  <dashboard-alerts-app-summary-patient-cell [patientName]="element[item.column]"
                    lastUpdated="Updated a min ago" program="Post Covid Monitoring"
                    [data]="element"></dashboard-alerts-app-summary-patient-cell>
                </ng-container>
    
                <ng-container *ngSwitchCase="'diagnosis'">
                  <dashboard-alerts-app-summary-diagnosis-cell [diagnosisText]="element[item.column]"
                    [patientId]="element.patientId" [data]="element"></dashboard-alerts-app-summary-diagnosis-cell>
                </ng-container>
    
                <ng-container *ngSwitchCase="'patientStatus'">
                  <dashboard-alerts-app-summary-status-cell statusText="Need Action"
                    [data]="element"></dashboard-alerts-app-summary-status-cell>
                </ng-container>
    
                <ng-container *ngSwitchCase="'heartRate'">
                  <dashboard-alerts-app-summary-heart-rate-cell [value]="element[item.column]" [data]="element"
                    [fetchData]="element?.fetchGoals[item.column]"
                    [unit]="'bpm'"></dashboard-alerts-app-summary-heart-rate-cell>
                </ng-container>
    
                <ng-container *ngSwitchCase="'spo2'">
                  <dashboard-alerts-app-summary-pusle-oximetery-cell [value]="element[item.column]" [data]="element"
                    [fetchData]="element?.fetchGoals[item.column]"
                    [unit]="'percent'"></dashboard-alerts-app-summary-pusle-oximetery-cell>
                </ng-container>
    
                <ng-container *ngSwitchCase="'bloodPressure'">
                  <dashboard-alerts-app-summary-blood-pressure-cell [value]="element[item.column]" [data]="element"
                    [fetchData]="element?.fetchGoals[item.column]"
                    [unit]="'mmHg'"></dashboard-alerts-app-summary-blood-pressure-cell>
                </ng-container>
    
                <ng-container *ngSwitchCase="'temperature'">
                  <dashboard-alerts-app-summary-temperature-cell [value]="element[item.column]" [data]="element"
                    [fetchData]="element?.fetchGoals[item.column]"
                    [unit]="'C'"></dashboard-alerts-app-summary-temperature-cell>
                </ng-container>
    
                <ng-container *ngSwitchCase="'bloodGlucose'">
                  <dashboard-alerts-app-summary-glucometer-cell [value]="element[item.column]" [data]="element"
                    [fetchData]="element?.fetchGoals[item.column]"
                    [unit]="'mmol/L'"></dashboard-alerts-app-summary-glucometer-cell>
                </ng-container>
    
                <ng-container *ngSwitchCase="'weight'">
                  <dashboard-alerts-app-summary-weight-cell [value]="element[item.column]" [data]="element"
                    [fetchData]="element?.fetchGoals[item.column]"
                    [unit]="'lbs'"></dashboard-alerts-app-summary-weight-cell>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div>{{ element[item.column] }}</div>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div>{{ element[item.column] }}</div>
            </ng-container>
          </ng-container>
        </mat-cell>
    
      </ng-container>
    
      <!-- </ng-container> -->
    
      <mat-header-row *matHeaderRowDef="columnIds"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnIds;" matRipple class="dashboard-element-row"></mat-row>
    </mat-table>

    <section *ngIf="!showSpinner && !tableDataSource.data.length" class="dashboard-no-mat-table-data">
      No data found!
    </section>

    <section *ngIf="showSpinner" class="dashboard-no-mat-table-data">
      <mat-spinner class="dashboard-rpm-spinner" [strokeWidth]="defaultSpinnerProperties.strokeWidth"
        [diameter]="defaultSpinnerProperties.diameter"></mat-spinner>
    </section>
  </section>

  

  <!-- Pagination -->
  <mat-paginator [pageSizeOptions]="paginationSizes" [pageSize]="defaultPageSize" (page)="onChangeItemPerPage()"
    [ngClass]="{'retina':retina, 'retina-xdr':retinaXdr,'retina-4k':retina4k}"></mat-paginator>
</div>