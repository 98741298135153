import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, catchError, map } from 'rxjs';
import { Bundle, BundleEntry, CarePlan, Condition, Goal, Patient } from 'fhir/r4';
import { mapPatientProgramDiagnosisGoalsBundle } from '../mappers/patient.mapper';
import { DefaultResourceQueryCount } from '../config/app.config';
import { BundleGetRequest } from '../mappers/shared.mapper';

@Injectable({
  providedIn: 'root'
})
export class PatientService extends BaseApiService {

  constructor(private http: HttpClient) {
    super()
  }

  /**
   * Gets the Patient FHIR resource for the provided patient fhir id
   * @param patientFhirId Patient's Fhir Id
   * @returns Patient resource | null if invalid response
   */
  getPatient(patientFhirId: string): Observable<Patient | null> {
    return this.http.get(`${this.FHIR_BASE}/Patient/${patientFhirId}`)
      .pipe(
        map((response: any) => {
          if (response) {
            return response as Patient
          }
          return null;
        }),
        catchError((error: any) => {
          console.error(error);
          return EMPTY;
        })
      )
  }
  /**
   * Gets the patient resource and the patients Condition from FHIR
   * based on the patient's FHIR id provided.
   * @param patientFhirId patients FHIR id
   * @returns Array of Patient and Condition of that patient or null
   */
  getPatientAndDiagnosis(patientFhirId: string): Observable<(Patient | Condition)[] | null> {
    return this.http.get(`${this.FHIR_BASE}/Patient?_id=${patientFhirId}&_revinclude=Condition:patient&_sort=-_lastUpdated`)
      .pipe(
        map((response: any) => {
          if (response.entry) {
            return response.entry.map((item: BundleEntry) => item.resource);
          }
          return null;
        }),
        catchError((error: any) => {
          console.error(error)
          return EMPTY;
        })
      );
  }

  /**
   * Fetch the patient details, corresponding condition, 
   * careplan and goals for that patient in a single bundle 
   * request. Response is a bundle of 2 bundle entries ,1st 
   * bundle has Patient & Condition, 2nd bundle has CarePlan 
   * and Goals[]
   * @param patientFhirId Patient FHIR ID
   * @returns 
   */
  getPatientProgramDiagnosisGoalsBundle(patientFhirId: string): Observable<{
    patient: Patient | null;
    program: CarePlan | null;
    diagnosis: Condition | null;
    goals: Goal[]
  } | null> {
    return this.http.post(`${this.FHIR_BASE}`,
      mapPatientProgramDiagnosisGoalsBundle(patientFhirId)
    )
      .pipe(
        map((response: any) => {
          if (!response?.entry?.length) {
            return null;
          }
          /** TODO: Extract the below code to a function similar to 
           * MapMultiBundlesToResourceArrays in shared.mapper.ts in patient app 
           */
          const patientDiagnosisBundle = response.entry?.[0]?.resource;
          const programGoalsBundle = response.entry?.[1]?.resource;
          return {
            patient: patientDiagnosisBundle?.entry?.find((item: any) => item?.resource.resourceType === "Patient")?.resource || null,
            diagnosis: patientDiagnosisBundle?.entry?.find((item: any) => item?.resource.resourceType === "Condition")?.resource || null,
            program: programGoalsBundle?.entry?.find((item: any) => item?.resource.resourceType === "CarePlan")?.resource || null,
            goals: programGoalsBundle?.entry?.filter((item: any) => item?.resource.resourceType === "Goal")
              ?.map((goal: any) => goal.resource) || []
          };
        })
      );
  }

  /**
   * Get the total number of active users for showing count in dashboard header (currently based on active careplan)
   */
  getActivePatients(selectedProgram: string): Observable<Bundle<CarePlan | Patient>> {
    const programSelectionQuery = selectedProgram && selectedProgram !== 'all'
      ? `&title:exact=${selectedProgram}`
      : '';
    return this.http.get<Bundle<Patient>>(`${this.FHIR_BASE}/CarePlan?status=active&_include=CarePlan:patient&_total=accurate&_count=${DefaultResourceQueryCount}${programSelectionQuery}`);
  }

  getPatientsWithActiveCarePlans(patientIds: string[]): Observable<Bundle> {
    const payload = BundleGetRequest(`/Patient?_id=${patientIds.join(',')}&_has%3ACarePlan%3Apatient%3Astatus=active`);
    return this.http.post<Bundle<Patient>>(this.FHIR_BASE, payload);
  }
}
