import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observation } from 'fhir/r4';
import { Observable, map, catchError, EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObservationService extends BaseApiService {

  constructor(private http: HttpClient) {
    super()
  }


  /**
   * Get the latest observation like Heart Rate or Blood Pressure
   * based on the observationType Provided, for the patient with
   * the provided patientFhirId
   * @param patientFhirId Patient's Fhir Id
   * @param observationType Observation type
   */
  getLatestObservation(
    patientFhirId: string,
    observationType: string,
    selectedProgram: string
  ): Observable<Observation | null> {
    const programSelectionQuery = selectedProgram && selectedProgram !== 'all'
      ? `&careplan-name=${selectedProgram}`
      : '';
    return this.http.get(`${this.FHIR_BASE}/Observation?code=${observationType}&subject=${patientFhirId}&_total=accurate&_sort=-_lastUpdated&_count=1${programSelectionQuery}`)
      .pipe(
        map((response: any) => {
          if (response?.entry?.length) {
            return response.entry[0].resource as Observation;
          }
          return null;
        }),
        catchError((error: any) => {
          console.error(error);
          return EMPTY;
        })
      );
  }
}
