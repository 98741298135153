import { ErrorHandler, Injectable } from '@angular/core';
import { SentryService } from './sentry.service';

@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandlerService implements ErrorHandler {

  constructor(private sentryService: SentryService) { }

  handleError(error: any): void {
    const sentry = this.sentryService.getSentry();
    if (sentry) {
      const errorDetails = {
        message: error.message,
        name: error.name,
      };
      console.log('[DASHBOARD] Sentry captured error', JSON.stringify(errorDetails));
      sentry.captureException(error);
    } else {
      throw Error('[DASHBOARD] Sentry is not initialized!');
    }
    console.error(error);
  }
}
