<div class="patients-title-row">
    <h2 mat-dialog-title class="patients-custom-dialog-title">{{confirmationHeader}}</h2>
    <mat-icon class="patients-pointer" [mat-dialog-close]="false">close</mat-icon>
</div>

<mat-dialog-content style="text-align: center;">
    {{confirmationQuestion}}

    <mat-dialog-actions [align]="'center'">
        <button mat-button [mat-dialog-close]="false"
            class="patients-basic-button empty patients-auto-width">{{dialogData.okButton ? 'Ok' : 'No'}}</button>
        <button mat-raised-button *ngIf="!dialogData.okButton" [mat-dialog-close]="true"
            color="primary" class="patients-basic-button filled patients-auto-width">Yes</button>
    </mat-dialog-actions>
</mat-dialog-content>