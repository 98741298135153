import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { OBSERVATION_CODES } from '../config/app.config';

@Injectable({
  providedIn: 'root'
})
export class AlertService extends BaseApiService {

  constructor(private http: HttpClient) {
    super();
  }

  private getParams(count = 0, selectedProgram: string): string {
    const programSelectionQuery = selectedProgram && selectedProgram !== 'all'
      ? `&careplan-name:exact=${selectedProgram}`
      : '';
    let params = `&status=ready&_total=accurate&_sort=-_lastUpdated`;
    if (count === 0) {
      params += `&_summary=count`;
    } else {
      params += `&_count=${count}`;
    }
    return `${params}${programSelectionQuery}`;
  }

  /**
   * Used to filter out the total count from alerts total
   * respoonse bundle, returns the total count from 
   * @param value Bundle or any
   * @returns number | null
   */
  filterTotalCount(value: any): number | null {
    if (value.total) {
      return value.total;
    }
    return null;
  }

  /**
   * Get Total Alerts Count. Here do not need the entire list of 
   * alert data, just only the total count, to avoid unnecessary
   * payload and data fetching, _count is set to 1. This will only
   * return the total count or null.
   * @param count - number of entries in response, for pagination, etc
   * defaults to 1, maximum limit is 1000
   * @returns Observable< number | null>
   */
  getAlertCount(count = 0, selectedProgram: string): Observable<number | any> {
    const params = this.getParams(count, selectedProgram);
    return this.http.get(`${this.FHIR_BASE}/Task?code:text=Patient Observation Alert${params}`);
  }

  /**
 * Get Heart Rate Alerts Count. Here do not need the entire list of 
 * alert data, just only the total count, to avoid unnecessary
 * payload and data fetching, _count is set to 1. This will only
 * return the total count or null.
 * @returns Observable< number | null>
 */
  getHeartRateAlertsCount(count = 0, selectedProgram: string): Observable<number | any> {
    const params = this.getParams(count, selectedProgram);
    return this.http
      .get(`${this.FHIR_BASE}/Task?code=${OBSERVATION_CODES.HEART_RATE}&code:text=Patient Observation Alert${params}`);
  }

  /**
 * Get SPO2 Alerts Count. Here do not need the entire list of 
 * alert data, just only the total count, to avoid unnecessary
 * payload and data fetching, _count is set to 1. This will only
 * return the total count or null.
 * @returns Observable< number | null>
 */
  getPulseOximeterAlertsCount(count = 0, selectedProgram: string): Observable<number | any> {
    const params = this.getParams(count, selectedProgram);
    return this.http
      .get(`${this.FHIR_BASE}/Task?code=${OBSERVATION_CODES.SPO2}&code:text=Patient Observation Alert${params}`);
  }

  /**
 * Get BloodPressure Alerts Count. Here do not need the entire list of 
 * alert data, just only the total count, to avoid unnecessary
 * payload and data fetching, _count is set to 1. This will only
 * return the total count or null.
 * @returns Observable< number | null>
 */
  getBloodPressureAlertsCount(count = 0, selectedProgram: string): Observable<number | any> {
    const params = this.getParams(count, selectedProgram);
    return this.http.get(`${this.FHIR_BASE}/Task?code=${OBSERVATION_CODES.BLOOD_PRESSURE}&code:text=Patient Observation Alert${params}`);
  }

  /**
 * Get Temperature Alerts Count. Here do not need the entire list of 
 * alert data, just only the total count, to avoid unnecessary
 * payload and data fetching, _count is set to 1. This will only
 * return the total count or null.
 * @returns Observable< number | null>
 */
  getTemperatureAlertsCount(count = 0, selectedProgram: string): Observable<number | any> {
    const params = this.getParams(count, selectedProgram);
    return this.http.get(`${this.FHIR_BASE}/Task?code=${OBSERVATION_CODES.TEMPERATURE}&code:text=Patient Observation Alert${params}`);
  }

  /**
 * Get Blood Glucose Alerts Count. Here do not need the entire list of 
 * alert data, just only the total count, to avoid unnecessary
 * payload and data fetching, _count is set to 1. This will only
 * return the total count or null.
 * @returns Observable< number | null>
 */
  getGlucometerAlertsCount(count = 0, selectedProgram: string): Observable<number | any> {
    const params = this.getParams(count, selectedProgram);
    return this.http.get(`${this.FHIR_BASE}/Task?code=${OBSERVATION_CODES.BLOOD_GLUCOSE}&code:text=Patient Observation Alert${params}`);
  }

  /**
 * Get Weight Alerts Count. Here do not need the entire list of 
 * alert data, just only the total count, to avoid unnecessary
 * payload and data fetching, _count is set to 1. This will only
 * return the total count or null.
 * @returns Observable< number | null>
 */
  getWeightAlertsCount(count = 0, selectedProgram: string): Observable<number | any> {
    const params = this.getParams(count, selectedProgram);
    return this.http.get(`${this.FHIR_BASE}/Task?code=${OBSERVATION_CODES.WEIGHT}&code:text=Patient Observation Alert${params}`);
  }
}
