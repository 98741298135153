import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-alerts-app-summary-patient-cell',
  templateUrl: './summary-patient-cell.component.html',
  styleUrls: ['./summary-patient-cell.component.scss'],
})
export class SummaryPatientCellComponent implements OnInit {
  /** Patient Name to render */
  @Input() patientName?: string;
  /** Last update time */
  @Input() lastUpdated?: string;

  /** Program name */
  @Input() program?: string;

  /** any other extra data if required to use */
  @Input() data?: any = null;

  constructor() { }

  ngOnInit(): void {
  }

}
