import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  MsalBroadcastService,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthError,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  SilentRequest,
} from '@azure/msal-browser';
import { Subject, filter, takeUntil, of, catchError } from 'rxjs';
import { BreakpointService } from './services/breakpoint.service';
import { activeRoute$, showSidebar$ } from '@bayshoreHealthCare/store';
import { CustomBreakpointState } from './shared/interfaces/custom-breakpoint-state-interface';
import { INavbarRoute } from './shared/interfaces/navbar-route';
import { InactivityService } from './services/inactivity.service';

@Component({
  selector: 'dashboard-alerts-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'dashboard-alerts-app';
  isIframe = false;
  loginDisplay = false;
  activeTab: INavbarRoute | null = null;
  private readonly unsubscribe = new Subject<void>();

  @HostBinding('class.tablet') isTablet: boolean = false;
  @HostBinding('class.sidebar-visible') isSidebarVisible = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private breakpointService: BreakpointService,
    private inactivityService: InactivityService
  ) { }

  ngOnInit(): void {
    this.setActiveTab({
      label: "Summary",
      location: "summary",
      title: "Dashboard"
    });
    
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        const accounts = this.authService.instance.getAllAccounts();
        const account = accounts[0];
        this.authService.instance.setActiveAccount(account);
        this.authService.acquireTokenSilent({ account } as SilentRequest).subscribe();
      });
    
    /** 
    * Navigate to this app's root path (dashboard home) whenever
    * we trigger route from sidebar app
    */
    activeRoute$
      .pipe(
        catchError((err: any) => {
          console.log('Dashboard App: RPM Store Active route error : ', err)
          return of(false);
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(({ activePage: url, isFromSideBar }: any) => {
        // #R2-421 Fixed the changeActiveRoute bug, so it redirects only the navigation triggers from sidebar
        if (String(url).toLowerCase() === "dashboard" && isFromSideBar) {
          // point to the root path or home path of this app
          this.router.navigate(['/summary']);
          this.setActiveTab({
            label: "Summary",
            location: "summary",
            title: "Dashboard"
          });
        }
      });
    
    this.breakpointService.breakpoint$
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((value: CustomBreakpointState) => {
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.isTablet = value.Tablet;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });

    /**
     * Listen sidebar toggle status
     */
    showSidebar$
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((sidebarVisible: boolean) => {
        this.isSidebarVisible = sidebarVisible;
      })

    // Remove this line to use Angular Universal
    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();
    
    // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.authService.instance.enableAccountStorageEvents();

    if (this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      const account = accounts[0];
      this.authService.instance.setActiveAccount(account);
      this.authService.acquireTokenSilent({ account } as SilentRequest).subscribe();
    }

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          console.log(`Dashboard: No account found:${this.authService.instance.getAllAccounts().length}`);
        } else {
          this.setLoginDisplay();
        }
      });

    // when session expires, the data and api will fail, and need the user to relogin. 
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe((result: EventMessage) => {
        const error = result.error as AuthError;
        if (error instanceof InteractionRequiredAuthError) {
          this.authService.loginRedirect();
        }
      });

    // #R2-962 - handled login redirect 
    const activeAccount = this.authService.instance.getActiveAccount();
    const isRedirectFromLogin = localStorage.getItem("redirect_from_login");
    if (!activeAccount && !isRedirectFromLogin) {
      window.location.replace('/auth');
    }

    this.inactivityService.startInactivityTimer();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
  checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  setActiveTab(tab: INavbarRoute) {
    this.activeTab = tab;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(undefined);
    this.unsubscribe.complete();
  }
}
