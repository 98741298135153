import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { MatTableDefaultPageSizes, spinnerProperties } from 'src/app/config/app.config';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { CustomBreakpointState } from '../../interfaces/custom-breakpoint-state-interface';
import { changeActiveRoute } from '@bayshoreHealthCare/store';

@Component({
  selector: 'dashboard-alerts-app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class CustomTableComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: false }) matPaginator!: MatPaginator;

  @Input() displayedColumns: Array<{ column: string, title: string }> = [];
  @Input() isPageAble = false;
  @Input() isExpandableRow = true;
  @Input() showSpinner = true;
  /** Optional type, for alerts table use 'alertsTable' */
  @Input() type: string | 'alertsTable' = '';

  @Input() tableDataSource = new MatTableDataSource<any>([]);

  /** dynamically get changes from parent component */
  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }

  @Input() trackBy?: any;

  columnIds: string[] = [];
  paginationSizes: number[] = MatTableDefaultPageSizes;
  defaultPageSize = this.paginationSizes[0];


  // spinner Properties like diameter and stroke width
  defaultSpinnerProperties = spinnerProperties;

  unsubscribe$: Subject<void> = new Subject();
  @HostBinding('class.tablet') isTablet: boolean = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private breakpointService: BreakpointService
  ) { }

  ngOnInit(): void {
    this.columnIds = this.displayedColumns.map(c => c.column);
    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: CustomBreakpointState) => {
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.isTablet = value.Tablet;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['displayedColumns']?.currentValue && changes['displayedColumns']?.previousValue) {
      if (changes['displayedColumns']?.currentValue.length !== changes['displayedColumns']?.previousValue?.length) {
        this.columnIds = changes['displayedColumns']?.currentValue?.map((c: any) => c.column);
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setTableDataSource(data: any): void {
    this.tableDataSource = new MatTableDataSource<any>(data);
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.sort;
  }

  isExpansionDetailRow = (i: number, row: Object) =>
    row.hasOwnProperty('detailRow');
  expandedElement: any;

  redirectToPatientSummary(data: any) {
    // #R2-421 Fixed the changeActiveRoute bug, so it doesn't redirect to /patients/active
    changeActiveRoute({ activePage: 'Patient' });
    // #R2-1150: if redirectToPatientSummary is clicked from dashboard app, then the assumption is that patient status is active
    this.router.navigateByUrl(`../patient/${data.patientId}/summary/active`, {
      replaceUrl: true
    });
  }

  /**
   * #R2-584 Firefox main element scroll height issue fix
   */
  onChangeItemPerPage(){
    if (navigator.userAgent.indexOf("Firefox") === -1) return;
    setTimeout(() => {
      const mainElement = document.querySelector("main");
      if (mainElement) {
        mainElement.scrollTop = (document.querySelector("main > div:last-child") as HTMLDivElement).scrollHeight - 900;
      }
    }, 100)
  }
}
