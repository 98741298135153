import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../material/material.module';
import { DashboardButtonComponent } from './components/dashboard-button/dashboard-button.component';
import { AssetUrlPipe } from './pipes/asset-url.pipe';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { SummaryPatientCellComponent } from './components/summary-patient-cell/summary-patient-cell.component';
import { SummaryDiagnosisCellComponent } from './components/summary-diagnosis-cell/summary-diagnosis-cell.component';
import { SummaryStatusCellComponent } from './components/summary-status-cell/summary-status-cell.component';
import { SummaryHeartRateCellComponent } from './components/summary-heart-rate-cell/summary-heart-rate-cell.component';
import { SummaryBloodPressureCellComponent } from './components/summary-blood-pressure-cell/summary-blood-pressure-cell.component';
import { SummaryPusleOximeteryCellComponent } from './components/summary-pusle-oximetery-cell/summary-pusle-oximetery-cell.component';
import { SummaryTemperatureCellComponent } from './components/summary-temperature-cell/summary-temperature-cell.component';
import { SummaryGlucometerCellComponent } from './components/summary-glucometer-cell/summary-glucometer-cell.component';
import { SummaryWeightCellComponent } from './components/summary-weight-cell/summary-weight-cell.component';


@NgModule({
  declarations: [
    DashboardButtonComponent,
    AssetUrlPipe,
    CustomTableComponent,
    SummaryPatientCellComponent,
    SummaryDiagnosisCellComponent,
    SummaryStatusCellComponent,
    SummaryHeartRateCellComponent,
    SummaryBloodPressureCellComponent,
    SummaryPusleOximeteryCellComponent,
    SummaryTemperatureCellComponent,
    SummaryGlucometerCellComponent,
    SummaryWeightCellComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
  ],
  exports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    DashboardButtonComponent,
    AssetUrlPipe,
    CustomTableComponent
  ],
})
export class SharedModule { }
