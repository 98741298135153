import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PredictiveAnalyticsComponent } from './components/predictive-analytics/predictive-analytics.component';
import { SummaryComponent } from './components/summary/summary.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'summary' },
  { path: 'summary', component: SummaryComponent },
  { path: 'predictive-analytics', component: PredictiveAnalyticsComponent },
  { path: '**', component: EmptyRouteComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [{ provide: APP_BASE_HREF, useValue: '/dashboard' }],
  exports: [RouterModule]
})
export class AppRoutingModule { }
