import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'dashboard-alerts-app-summary-alerts-card',
  templateUrl: './summary-alerts-card.component.html',
  styleUrls: ['./summary-alerts-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryAlertsCardComponent implements OnInit, OnChanges {
  /** Title of the card */
  @Input() title: string = '';

  /** Count to be shown in the card */
  @Input() count: string | number = 0;

  /** Subtitle to show in the card */
  @Input() subtitle: 'overall' | 'today' | string = 'today';

  /** Can be set to pass the background color of the card */
  @Input() backgroundColor: string = '';

  /** Icon to be shown in the card1 */
  @Input() imageUrl: any;

  /** Data which can be used to emit on any event */
  @Input() data: any = {};

  /** Callback function */
  @Input() click: any;

  @Input() cdkDragData!: number;

  /** Style object passed to card, used to calculate style based on inputs */
  style = {};

  @HostBinding('class.tablet') isTablet: boolean = false;
  @HostBinding('class.sidebar-visible') isSidebarVisible = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['backgroundColor']?.currentValue !== changes['backgroundColor']?.previousValue) {
      this.style = {
        'background-color': changes['backgroundColor']?.currentValue ? changes['backgroundColor']?.currentValue : 'white',
        'color': changes['backgroundColor']?.currentValue ? 'white' : 'black'
      };
    }
  }

  handleClick() {
    this.click(this.data ?? {});
  }

}
