<div class="blood-pressure-cell-container" *ngIf="value">
    <div class="value-container">
        <span>{{ value}} {{data?.alertUnit?- data?.alertUnit:''}} {{unit}}</span>
    </div>
    <div class="icon-container">
        <img src="{{'images/blood-pressure.png'|assetUrl}}" class="blood-pressure-icon">
    </div>
</div>

<div class="blood-pressure-cell-container" *ngIf="!value && observationValue">
    <div class="value-container">
        <span>{{ observationValue }} mmHg</span>
    </div>
    <div class="icon-container">
        <img src="{{'images/blood-pressure.png'|assetUrl}}" class="blood-pressure-icon">
    </div>
</div>

<div class="blood-pressure-cell-container" *ngIf="!value && !observationValue">
    <div class="value-container   ">
        <span>-- mmHg</span>
    </div>
    <div class="icon-container">
        <img src="{{'images/blood-pressure.png'|assetUrl}}" class="blood-pressure-icon">
    </div>
</div>