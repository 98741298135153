import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observation } from 'fhir/r4';
import { OBSERVATION_CODES } from 'src/app/config/app.config';
import { CareplanService } from 'src/app/services/careplan.service';
import { ObservationService } from 'src/app/services/observation.service';

@Component({
  selector: 'dashboard-alerts-app-summary-weight-cell',
  templateUrl: './summary-weight-cell.component.html',
  styleUrls: ['./summary-weight-cell.component.scss']
})
export class SummaryWeightCellComponent implements OnChanges {
  /** Weight value */
  @Input() value!: string;

  /**Optional Unit text to show */
  @Input() unit?: string = '';

  /** Extra data if required */
  @Input() data?: any = null;

  /**Whether to fetch data or not */
  @Input() fetchData?: boolean = false;

  observationValue: number | null = null;

  selectedProgram!: string;

  constructor(
    private observationService: ObservationService,
    private carePlanService: CareplanService,
  ) {
    this.carePlanService.programSelectionChanged$
      .subscribe((selectedProgram: string) => {
        this.selectedProgram = selectedProgram;
      });
  }

  addObservationSubscription() {
    if (this.data.patientId && !this.value) {
      this.observationService
        .getLatestObservation(this.data.patientId, OBSERVATION_CODES.WEIGHT, this.selectedProgram)
        .subscribe({
          next: (response: Observation | null) => {
            if (response) {
              this.observationValue = response.valueQuantity?.value || null;
            }
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      if (changes['data']?.isFirstChange() && !changes['data']?.previousValue?.weight && changes['data']?.currentValue?.weight === null) {
        this.addObservationSubscription();
      }
    }
  }

}
