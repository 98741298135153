import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Bundle, CarePlan, Patient, PlanDefinition } from 'fhir/r4';
import { EMPTY, Subject, catchError, combineLatest, switchMap, takeUntil, tap } from 'rxjs';
import { FhirResourceType, spinnerProperties } from 'src/app/config/app.config';
import { MapBundleToResourceArray, MapBundleToResourceArrays } from 'src/app/mappers/shared.mapper';
import { CareplanService } from 'src/app/services/careplan.service';
import { PatientService } from 'src/app/services/patient.service';
import { INavbarRoute } from 'src/app/shared/interfaces/navbar-route';

@Component({
  selector: 'dashboard-alerts-app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() activeTab: INavbarRoute | null = null;
  userCount: number = 0;
  showSpinner: boolean = true;
  spinnerProps = { ...spinnerProperties, diameter: 20 };
  unsubscribe$: Subject<void> = new Subject();
  programs: Array<{ title: string }> = [];
  selectedProgram = 'all';

  constructor(
    private patientService: PatientService,
    private carePlanService: CareplanService,
  ) { 
    this.carePlanService.programSelectionChanged$
      .subscribe((selectedProgram: string) => {
        this.selectedProgram = selectedProgram;
      });
  }

  ngOnInit(): void {
    this.fetchAllPrograms();
    this.fetchActiveUserCount();
  }

  fetchAllPrograms(): void {
    this.carePlanService.getAllCarePlans()
      .subscribe((bundle: Bundle<PlanDefinition>) => {
        const planDefinitions: PlanDefinition[] = MapBundleToResourceArray<PlanDefinition>(bundle);
        this.programs = planDefinitions
          .filter((planDefinition: PlanDefinition) => planDefinition.status === 'active')
          .map(({ title = '' }: PlanDefinition) => {
            if (!title) {
              throw new Error('Title in PlanDefinition not accessible');
            }
            return {
              title,
            }
          });
      }); 
  }

  onSelectedProgramChange(selectedProgram: string): void {
    this.carePlanService.programSelectionChanged$.next(selectedProgram);
  }

  /**
   * Fetch all the api required based on the 
   */
  fetchActiveUserCount() {
    combineLatest([
      this.carePlanService.programSelectionChanged$,
      this.carePlanService.fetchActiveUsers$,
    ])
    .pipe(
      switchMap(([selectedProgram]) => {
        this.showSpinner = true;
        return this.patientService.getActivePatients(selectedProgram)
          .pipe(
            tap((bundle: Bundle<CarePlan | Patient>) => {
              const { resource1: patients } = MapBundleToResourceArrays(bundle, FhirResourceType.Patient);
              this.userCount = patients.length;
              this.showSpinner = false;
            }),
            takeUntil(this.unsubscribe$),
            catchError((error: any) => {
              console.error(error);
              this.showSpinner = false;
              return EMPTY;
            })
          )
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
