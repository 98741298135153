import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Coding, Observation, ObservationComponent } from 'fhir/r4';
import { BLOOD_PRESSURE_CODES, OBSERVATION_CODES } from 'src/app/config/app.config';
import { CareplanService } from 'src/app/services/careplan.service';
import { ObservationService } from 'src/app/services/observation.service';

@Component({
  selector: 'dashboard-alerts-app-summary-blood-pressure-cell',
  templateUrl: './summary-blood-pressure-cell.component.html',
  styleUrls: ['./summary-blood-pressure-cell.component.scss']
})
export class SummaryBloodPressureCellComponent implements OnChanges {
  /** Blood pressure value */
  @Input() value!: string;

  /**Optional Unit text to show */
  @Input() unit?: string = '';

  /** Extra data if required */
  @Input() data?: any = null;

  /**Whether to fetch data or not */
  @Input() fetchData?: boolean = false;

  observationValue: string | null = null;

  selectedProgram!: string;

  constructor(
    private observationService: ObservationService,
    private carePlanService: CareplanService,
  ) { 
    this.carePlanService.programSelectionChanged$
      .subscribe((selectedProgram: string) => {
        this.selectedProgram = selectedProgram;
      });
  }

  findSystolicAndDiastolic = (record: Observation) => {
    const systolicComponent = record.component?.find((value: ObservationComponent) => {
      return value.code.coding?.find((code: Coding) => code.code === BLOOD_PRESSURE_CODES.SYSTOLIC);
    });
    const diastolicComponent = record.component?.find((value: ObservationComponent) => {
      return value.code.coding?.find((code: Coding) => code.code === BLOOD_PRESSURE_CODES.DIASTOLIC);
    });
    return {
      systolicValue: systolicComponent?.valueQuantity?.value,
      diastoliValue: diastolicComponent?.valueQuantity?.value
    }
  }

  addObservationSubscription() {
    if (this.data.patientId && !this.value) {
      this.observationService
        .getLatestObservation(this.data.patientId, OBSERVATION_CODES.BLOOD_PRESSURE, this.selectedProgram)
        .subscribe({
          next: (response: Observation | null) => {
            if (response) {
              const { systolicValue, diastoliValue } = this.findSystolicAndDiastolic(response);
              this.observationValue = `${systolicValue ? `${systolicValue} - ` : ''}${diastoliValue || ''}` || null;
            }
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      if (changes['data']?.isFirstChange() && !changes['data']?.previousValue?.bloodPressure && changes['data']?.currentValue?.bloodPressure === null) {
        this.addObservationSubscription();
      }
    }
  }

}
