<div class="heart-rate-cell-container" *ngIf="value">
    <div class="value-container">
        <span>{{ value }} {{unit}}</span>
    </div>
    <div class="icon-container">
        <img src="{{'images/heart-rate.png'|assetUrl}}" class="heart-rate-icon">
    </div>
</div>

<div class="heart-rate-cell-container" *ngIf="!value && observationValue">
    <div class="value-container">
        <span>{{ observationValue }} bpm</span>
    </div>
    <div class="icon-container">
        <img src="{{'images/heart-rate.png'|assetUrl}}" class="heart-rate-icon">
    </div>
</div>

<div class="heart-rate-cell-container" *ngIf="!value && !observationValue">
    <div class="value-container">
        <span>--  bpm</span>
    </div>
    <div class="icon-container">
        <img src="{{'images/heart-rate.png'|assetUrl}}" class="heart-rate-icon">
    </div>
</div>