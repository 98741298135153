<section class="program-filter-wrapper">
  <div>
    <div class="dashboard-header-title" *ngIf="!!activeTab">{{ activeTab?.title }}</div>
    <span class="dashboard-header-counter-container" *ngIf="!showSpinner && activeTab && activeTab.label==='Summary'">
      <mat-icon>person</mat-icon>
      <p>{{userCount}} Active users</p>
    </span>
  </div>
  <div>
  <div class="program-filter">
    <span class="program-filter-title">Programs</span>
    <div class="bsh-mat-select-wrapper" align="right">
      <mat-form-field>
        <mat-select [(ngModel)]="selectedProgram" (ngModelChange)="onSelectedProgramChange($event)" class="bsh-drop-down">
          <mat-option [value]="'all'">All</mat-option>
          <mat-option *ngFor="let program of programs" [value]="program.title">{{program.title}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  </div>
</section>
<mat-spinner *ngIf="showSpinner" class="dashboard-rpm-spinner" [strokeWidth]="spinnerProps.strokeWidth"
    [diameter]="spinnerProps.diameter" mode="indeterminate"></mat-spinner>