import { ApiConfig } from 'src/app/config/app.config';

export const environment = {
  production: false,
  clientId: '59a2e79c-4db6-49e5-aed8-ac4abccb8c0d',
  redirectURL: 'https://dev-vantage.betteroutcomes.health',
  authority:
    'https://login.microsoftonline.com/b3cbe65b-2f59-494f-a24b-571b825d92d2'
};

export const api: Readonly<ApiConfig> = {
  fhirBase: 'https://dev-vantage-api.betteroutcomes.health/api/fhir-service',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
