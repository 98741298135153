import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, Subject, takeUntil, BehaviorSubject } from 'rxjs';
import { customBreakpoints } from '../config/app.config';
import { CustomBreakpointState } from '../shared/interfaces/custom-breakpoint-state-interface';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  unsubscribe: Subject<void> = new Subject();
  defaultBreakpointState: CustomBreakpointState = {
    Tablet: false,
    Large: false,
    XLarge: false,
    Medium: false,
    Retina: false,
    RetinaXDR: false,
    Retina4k: false,
    Retina5k: false
  };
  breakpoint$: BehaviorSubject<CustomBreakpointState> = new BehaviorSubject(this.defaultBreakpointState);


  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(
      [
        customBreakpoints.Tablet,
        customBreakpoints.Medium,
        customBreakpoints.Large,
        customBreakpoints.XLarge,
        customBreakpoints.Retina,
        customBreakpoints.RetinaXDR,
        customBreakpoints.Retina4k,
        customBreakpoints.Retina5k
      ]
    )
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      ).subscribe(() => {
        if (this.breakpointObserver.isMatched(customBreakpoints.Tablet)) {
          this.breakpoint$.next({ ...this.defaultBreakpointState, Tablet: true });
        }
        if (this.breakpointObserver.isMatched(customBreakpoints.Medium)) {
          this.breakpoint$.next({ ...this.defaultBreakpointState, Medium: true });
        }
        if (this.breakpointObserver.isMatched(customBreakpoints.Large)) {
          this.breakpoint$.next({ ...this.defaultBreakpointState, Large: true });
        }
        if (this.breakpointObserver.isMatched(customBreakpoints.XLarge)) {
          this.breakpoint$.next({ ...this.defaultBreakpointState, XLarge: true });
        }
        if (this.breakpointObserver.isMatched(customBreakpoints.Retina)) {
          this.breakpoint$.next({ ...this.defaultBreakpointState, Retina: true });
        }
        if (this.breakpointObserver.isMatched(customBreakpoints.RetinaXDR)) {
          this.breakpoint$.next({ ...this.defaultBreakpointState, RetinaXDR: true });
        }
        if (this.breakpointObserver.isMatched(customBreakpoints.Retina4k)) {
          this.breakpoint$.next({ ...this.defaultBreakpointState, Retina4k: true });
        }
        if (this.breakpointObserver.isMatched(customBreakpoints.Retina5k)) {
          this.breakpoint$.next({ ...this.defaultBreakpointState, Retina5k: true });
        }
      });
  }
}
