import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-alerts-app-summary-diagnosis-cell',
  templateUrl: './summary-diagnosis-cell.component.html',
  styleUrls: ['./summary-diagnosis-cell.component.scss']
})
export class SummaryDiagnosisCellComponent implements OnInit {
  @Input() diagnosisText!: string;
  @Input() data: any = null;
  @Input() patientId!: string;

  constructor() { }

  ngOnInit(): void {
  }

}
