<div cdkDropListGroup class="dashboard-summary-alerts-container">
    <dashboard-alerts-app-summary-alerts-card (cdkDropListDropped)="drop($event)" cdkDropList
        [cdkDropListOrientation]="'horizontal'" [cdkDropListData]="i" *ngFor="let alert of alerts;let i = index;"
        [title]="alert.title" [subtitle]="alert.subtitle" [count]="alert.count"
        [backgroundColor]="alert?.cardBackground ?? ''" [imageUrl]="alert.imageUrl" [click]="handleCardClick"
        [data]="alert" [cdkDragData]="i"></dashboard-alerts-app-summary-alerts-card>
</div>
<div class="dashboard-summary-heading-row">
    <div class="dashboard-summary-heading-column">
        <span class="dashboard-summary-heading">
            {{ selectedAlertType }}
        </span>
    </div>
    <div class="dashboard-summary-heading-column">
        <!-- <dashboard-alerts-app-dashboard-button title="Filter"
            [prefixImageUrl]="'images/filter.png'"></dashboard-alerts-app-dashboard-button> -->
        <dashboard-alerts-app-dashboard-button title="Columns" [prefixImageUrl]="'images/column.png'"
            [suffixIconName]="'expand_more'"
            [matMenuTriggerFor]="columnsFilter"></dashboard-alerts-app-dashboard-button>

        <mat-menu #columnsFilter="matMenu" xPosition="before" yPosition="below">
            <button mat-menu-item class="dashboard-summary-column-filter-menu-item"
                [matMenuTriggerFor]="systemColumnFilter">System</button>
            <button mat-menu-item class="dashboard-summary-column-filter-menu-item"
                [matMenuTriggerFor]="metricsColumnFilter">Metrics</button>
        </mat-menu>

        <mat-menu #systemColumnFilter="matMenu" [formGroup]="systemColumnsForm" xPosition="before" yPosition="below">
            <ng-container *ngFor="let system of systemColumns">
                <button mat-menu-item (click)="$event.stopPropagation()">
                    <mat-checkbox class="dashboard-checkbox"
                        formControlName="{{system.column}}">{{system.title}}</mat-checkbox>
                </button>
            </ng-container>
        </mat-menu>

        <mat-menu #metricsColumnFilter="matMenu" [formGroup]="metricsColumnsForm" xPosition="before" yPosition="below">
            <ng-container *ngFor="let system of metricsColumns">
                <button mat-menu-item (click)="$event.stopPropagation()">
                    <mat-checkbox class="dashboard-checkbox"
                        formControlName="{{system.column}}">{{system.title}}</mat-checkbox>
                </button>
            </ng-container>
        </mat-menu>
    </div>
</div>
<dashboard-alerts-app-custom-table [displayedColumns]="displayedColumns" [type]="'alertsTable'"
    [showSpinner]="showTableSpinner" [tableData]="alertsTableData"></dashboard-alerts-app-custom-table>